import React, { Children } from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import notFound from "../images/404_artwork.png"

const ErrorContainer = styled.div`
  display: grid;
  grid-template-columns: 25% 30%;
  grid-column-gap: 60px;
  justify-content: space-evenly;
  align-content: center;
  margin: 100px 0;
  @media (max-width: 960px) {
    grid-template-columns: 35% 40%;
  }
  @media (max-width: 575px) {
    display: flex;
    flex-direction: column-reverse;
    padding: 0 20px;
    margin: 50px 0;
  }
`

const ErrorTextSection = styled.div`
  display: grid;
  grid-template-columns: auto;
  font-family: 'Khula', sans-serif;
  align-content: center;
  @media (max-width: 575px) {
    grid-row-gap: 10px;
    text-align: center;
  }
`

const Oops = styled.p`
  color: #33475b;
  font-size: 40px;
  margin-bottom: 0;
`
const NothingHere = styled.p`
  color: #8c8c8c;
  font-size: 30px;
  margin-bottom: 0;
`
const Sorry = styled.p`
  color: #8c8c8c;
  font-size: 20px;
  margin-bottom: 0;
  margin-top: 15px;
`
const browser = typeof window !== "undefined" && window;

export default () => (
  browser && (
    <ErrorContainer>
      <ErrorTextSection>
        <Oops>OOPS!</Oops>
        <NothingHere>Nothing here.</NothingHere>
        <Sorry>Sorry. We couldn't find that page.</Sorry>
      </ErrorTextSection>
      <div>
        <img src={notFound} alt="404" width="100%" />
      </div>
    </ErrorContainer>
  )
)
